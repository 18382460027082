import PropTypes from "prop-types";
import { useColorModeValue } from "@chakra-ui/react";

const PRIMARY_COLOR = "#18214d";
const SECONDARY_COLOR = "#ee3465";
const WHITE_COLOR = "#fff";

IconLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

IconLogo.defaultProps = {
  width: "120px",
  height: "91px",
};

export default function IconLogo({ width, height }) {
  const isDarkMode = useColorModeValue(false, true);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 91" // The SVG was exported from a 120x91 px canvas
      fill="none"
    >
      <path
        fill={isDarkMode ? SECONDARY_COLOR : PRIMARY_COLOR}
        d="M97.003 77.845l-7.328-46.301a8.24 8.24 0 00-8.103-6.885h-5.003c-.141-1.686-.423-3.302-.775-4.988a31.16 31.16 0 00-1.057-3.654c-.423-1.194-.987-2.389-1.55-3.583-.282-.562-.634-1.124-.987-1.686s-.775-1.124-1.198-1.616c-.916-1.054-1.903-2.038-3.1-2.881-2.466-1.616-5.496-2.459-8.315-2.319-2.819.07-5.778 1.194-7.963 2.951s-3.805 3.934-5.003 6.253-2.044 4.707-2.678 7.096a48.94 48.94 0 00-.846 4.426h-5.144a8.24 8.24 0 00-8.103 6.885l-3.523 21.991-3.876 24.31c-.775 4.988 3.03 9.485 8.104 9.485H88.9c5.003-.07 8.879-4.567 8.104-9.485zm-51.44-53.186c1.127-3.443 2.537-6.745 4.369-9.625 1.268-1.827 2.607-3.583 4.298-4.707.423-.281.846-.562 1.268-.773a10.86 10.86 0 011.339-.562c.916-.281 1.902-.492 2.889-.492 2.044-.07 3.876.422 5.567 1.475 1.621 1.054 3.03 2.67 4.228 4.567 1.127 1.897 2.114 3.935 2.819 6.113.493 1.265.846 2.67 1.198 4.005H45.563z"
      />
      <g fill={isDarkMode ? WHITE_COLOR : SECONDARY_COLOR}>
        <path
          d="M44.083 41.591c.07 1.757.352 3.372.705 5.199l.986 3.583c.423 1.194.916 2.319 1.48 3.513.564 1.124 1.268 2.248 2.114 3.232s1.832 1.967 2.96 2.74c2.255 1.546 5.074 2.389 7.822 2.248 1.339 0 2.748-.351 4.017-.773 1.268-.492 2.537-1.124 3.594-1.967 2.114-1.686 3.735-3.794 4.933-6.042s2.044-4.637 2.678-7.026a37.93 37.93 0 00.916-4.778l-1.198-.07c-1.127 3.724-2.678 7.377-4.651 10.539-1.268 1.897-2.678 3.654-4.439 4.918s-3.735 1.967-5.849 2.037-4.157-.421-5.919-1.616c-1.762-1.124-3.242-2.81-4.439-4.707s-2.184-4.075-2.959-6.253c-.564-1.616-1.057-3.091-1.48-4.778h-1.268z"
          opacity="0.15"
        />
        <path d="M79.386 37.376c0 2.108-1.55 3.864-3.523 4.215l-.493 2.248c-.634 2.389-1.48 4.778-2.678 7.026s-2.748 4.356-4.932 6.042c-1.057.843-2.255 1.546-3.594 1.967-1.268.492-2.678.773-4.017.773-2.748.141-5.567-.703-7.822-2.248-1.127-.773-2.114-1.757-2.959-2.74s-1.55-2.108-2.114-3.232a30.86 30.86 0 01-1.48-3.513c-.423-1.194-.705-2.389-.986-3.583l-.493-2.67c-2.325 0-4.228-1.897-4.228-4.216s1.903-4.215 4.228-4.215 4.228 1.897 4.228 4.215c0 1.827-1.127 3.302-2.678 3.935l.846 2.459c.775 2.248 1.762 4.356 2.959 6.253s2.678 3.583 4.439 4.707 3.805 1.686 5.919 1.616 4.087-.773 5.849-2.037 3.171-3.021 4.439-4.918c1.55-2.459 2.748-5.129 3.805-7.939a4.23 4.23 0 01-3.312-4.145c0-2.319 1.902-4.215 4.228-4.215 2.466 0 4.369 1.897 4.369 4.215z" />
      </g>
      <path
        fill={isDarkMode ? SECONDARY_COLOR : PRIMARY_COLOR}
        d="M108.982 34.776l-5.567 1.265 5.214 6.534-2.818 2.881-5.567-6.745-3.171 4.637-2.959-15.808 14.868 7.237zM3.777 67.587l5.567 1.265-5.214 6.534 2.819 2.881 5.567-6.745 3.171 4.637 2.96-15.808-14.868 7.237zm97.524 1.405a6.92 6.92 0 004.51-4.637c.634 2.248 2.325 4.005 4.51 4.637a6.92 6.92 0 00-4.51 4.637c-.564-2.178-2.325-3.934-4.51-4.637zM16.672 42.013c.634-2.248 2.396-4.075 4.58-4.778-2.255-.703-4.017-2.529-4.58-4.778-.634 2.248-2.396 4.075-4.58 4.778 2.255.703 4.017 2.529 4.58 4.778z"
      />
      <g fill={isDarkMode ? WHITE_COLOR : SECONDARY_COLOR}>
        <path d="M112.012 56.767c-.846 0-1.48-.703-1.48-1.475 0-.843.705-1.476 1.48-1.476a1.54 1.54 0 011.48 1.476 1.44 1.44 0 01-1.48 1.475zm-100.484-5.41c0-.843-.705-1.476-1.48-1.476a1.54 1.54 0 00-1.48 1.476c0 .843.705 1.475 1.48 1.475s1.48-.632 1.48-1.475z" />
        <path
          d="M86.362 82.974l-13.036.492s-4.862.211-.071-1.616c6.483-2.529 14.516-8.22 15.996-21.288a.46.46 0 01.916 0l2.185 14.473c.986 3.935-1.973 7.728-5.99 7.939z"
          opacity="0.3"
        />
      </g>
    </svg>
  );
}
