import PropTypes from "prop-types";
import IconLogo from "~/components/logo/IconLogo";
import TextLogo from "~/components/logo/TextLogo";
import FullHorizontalLogo from "~/components/logo/FullHorizontalLogo";

Logo.propTypes = {
  type: PropTypes.oneOf(["full", "icon", "text"]),
};

Logo.defaultProps = {
  type: "full",
};

export default function Logo({ type, ...otherProps }) {
  switch (type) {
    case "icon":
      return <IconLogo {...otherProps} />;
    case "text":
      return <TextLogo {...otherProps} />;
    case "full":
    default:
      return <FullHorizontalLogo {...otherProps} />;
  }
}
